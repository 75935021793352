<template>
    <v-container class="pa-0" fluid>
        <page-title
                :heading="$t('generic.lang_timecardMonthly') "
                :icon="icon"
                :subheading="$t('generic.lang_timecardMonthly')"
        ></page-title>
        <div class="app-main__inner">
            <monthly-time-card-component />
        </div>
    </v-container>
</template>

<script>
    //components
    import PageTitle from "../../../Layout/Components/PageTitle.vue";
    import DailyTimeCardComponent from "../../../components/timesheet/dailytimecard/DailyTimeCardComponent";
    import MonthlyTimeCardComponent from "../../../components/timesheet/monthlytimecard/MonthlyTimeCardComponent";

    export default {
        components: {
            MonthlyTimeCardComponent,
            PageTitle,

        },
        data: () => ({
            icon: "pe-7s-date icon-gradient bg-tempting-azure"
        })
    };
</script>

<style>
</style>