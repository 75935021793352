<template>

    <div class="mb-3 card">
        <div>

            <!-- Table -->
            <Datatable :api-endpoint="ENDPOINTS.DATATABLES.SETTINGS.EMPLOYEES"
                       :custom-buttons="this.custombuttons"
                       :datatable-headers="datatableHeaders"
                       :excel-columns="excelColumns"
                       :permissionDelete="this.$store.getters['permissions/checkPermission']('user_delete')"
                       :permissionEdit="this.$store.getters['permissions/checkPermission']('user_edit')"
                       :v-show="true"
                       @track="track"
                       excel-file-name="Benutzer_Zeitkarte"
                       ref="userDatatable"
                       show-select-checkbox
            />


        </div>

        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
            <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                                :defaultKeySet="touchKeyboard.keySet"
                                :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                                :options="touchKeyboard.options" class="internalWidthExpanded"
                                id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
        </div>
    </div>
</template>

<script>
    import {ENDPOINTS} from '../../../config'
    import Datatable from "../../datatable/Datatable";
    import mixin from "../../../mixins/KeyboardMixIns";

    export default {
        components: {
            Datatable
        },
        mixins: [mixin],
        data() {
            return {
                ENDPOINTS,
                custombuttons: [
                    {
                        text: "timer",
                        color: "success",
                        eventRow: "track",
                        layout: {
                            outlined: false,
                            flat: false,
                            text: false,
                            icon: true
                        }
                    }
                ],
                // --- Datatable ---
                datatableHeaders: [
                    {
                        text: 'ID',
                        align: 'left',
                        value: 'id',
                        width: 80,
                    },
                    {text: this.$t("customers.lang_cust_prename"), value: 'prename'},
                    {text: this.$t("customers.lang_cust_name"), value: 'lastname'},
                    {text: this.$t("customers.lang_cust_birth"), value: 'birth', hide: true},
                    {text: this.$t("generic.lang_emailAdresse"), value: 'email'},
                    {text: this.$t("customers.lang_cust_mobil_priv"), value: 'tel_private'},
                ],
                excelColumns: [

                    {
                        label: "ID",
                        field: "id",
                    },
                    {
                        label: this.$t("customers.lang_cust_prename"),
                        field: "prename",
                    },
                    {
                        label: this.$t("customers.lang_cust_name"),
                        field: "lastname",
                    },
                    {
                        label: this.$t("customers.lang_cust_birth"),
                        field: "birth",
                    },
                    {
                        label: this.$t("generic.lang_emailAdresse"),
                        field: "email",
                    },
                    {label: this.$t("customers.lang_cust_mobil_priv"), field: 'tel_private'},
                ],
            }
        },

        methods: {
            track(entry) {
                this.$router.push('/timetrack/timeCardDaily/track/' + entry.id);
            },
        },
    }
</script>